import React, { useState, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import Container from '@mlcl-digital/mlcl-design/lib/base/Container'
import GridContainer from '@mlcl-digital/mlcl-design/lib/base/GridContainer'
import HeadingWithIcon from '@mlcl-digital/mlcl-design/lib/base/HeadingWithIcon'
import Icon from '@mlcl-digital/mlcl-design/lib/base/Icon'
import LabelValuePair from '@mlcl-digital/mlcl-design/lib/base/LabelValuePair'
import ActionLink from '@mlcl-digital/mlcl-design/lib/base/ActionLink'
import List from '@mlcl-digital/mlcl-design/lib/base/List'
import get from 'lodash/get'
import styled from '@emotion/styled'
import { Heading, Tooltip } from '@mlcl-digital/mlcl-design'

// FIXME: ts-expect-error comment can be removed when files are converted to typescript
// utils
import {
  healthyLivingDiscountVal,
  makeFeatureFromSubBenefits,
  getDiscountVal,
  // @ts-expect-error file not in typescript
} from '../../../../../utils/extendedQuoteUtils'
import { isFeatureEnabled } from '../../../../../utils/featureToggling'
import {
  getLifeInsuredNameList,
  convertFeatureCodeToFullName,
  // @ts-expect-error file not in typescript
} from '../../../../../utils/policyUtils'
import { getCollectionFrequencyUnit } from '../../../../../utils/paymentUtils'
import { reduceAuthorableFields, renderTextField } from '../../../../../utils/sitecoreUtils'
// @ts-expect-error file not in typescript
import { DATE_FORMAT_WITH_MONTH_NAME } from '../../../../../utils/quoteUtils'
// @ts-expect-error file not in typescript
import { getBenefitLoadingsDescription } from '../../../../../utils/commonUtils'
// @ts-expect-error file not in typescript
import { idMaker } from '../../../../../utils/formUtils'
import { createEvent } from '../../../../../utils/telemetry'

// constants
import {
  FEATURES_AS_SUB_BENEFITS,
  INCOME_PROTECTION_RELATED_POLICY_BENEFITS,
  BUSINESS_EXPENSE_RELATED_POLICY_BENEFITS,
  POLICY_BENEFIT_PTD,
  MULTI_COVER_DISCOUNT,
  INCOME_PROTECTION_ID,
  POLICY_PRODUCT_CODE_SUPER,
  POLICY_PRODUCT_CODE_NON_SUPER,
} from '../../../../../constants/policies'
import { NEW_STEPPED_LABEL, PREMIUM_STYLE_STEPPED } from '../../../../../constants/benefit'

// selectors
import { getMasterData, getConfig } from '../../../../../selectors/common.selectors'
import {
  getExtendedBenefitLabel,
  makeConnectedBenefitData,
} from '../../../../../selectors/clientPolicies'

// styles
import styles from './benefit.styles'

// types
import {
  benefit as benefitType,
  Relationship,
  feature as featureType,
  benefitAssured as benefitAssuredType,
} from '../../../../../types/ClientPolicies'
import { SitecoreField } from '../../../../../types/sitecore'
import { fieldsType } from '../../../../../types/components/AdvisorClientDetails'

const StyledContainer = styled(Container)(styles.container)
const AdditonalInfoContainer = styled('div')(styles.additonalInfoContainer)
const ListItemWithTooltip = styled('div')(styles.listItemWithTooltip)
const TooltipContent = styled(Tooltip)(styles.tooltipContent)
const ConnectedBenefitCTA = styled(ActionLink)(styles.connectedBenefitCTA)

type benefitProps = {
  benefitData: benefitType
  fields: fieldsType
  relationships: Relationship[]
  isPolicyOutOfForce: boolean
  isAssociatedBenefit: boolean
  isFirstChild: boolean
  onPolicyTabChange: (index: number) => void
  bancsPolicyNo: string
  legacySystemProductCode?: string
  productId?: string
}

type ItewWithTooltipProps = {
  label: SitecoreField
  tooltipContent: SitecoreField
  value: string
}

const Benefit = ({
  benefitData,
  fields,
  relationships,
  isPolicyOutOfForce,
  onPolicyTabChange,
  isAssociatedBenefit,
  isFirstChild,
  bancsPolicyNo,
  legacySystemProductCode,
  productId,
}: benefitProps) => {
  const [showBenefitAdditionaInfo, setToggleBenefitInfo] = useState(false)
  const config = useSelector(getConfig)
  const isCaliRelabel = isFeatureEnabled('CaliRelabel', config.FEATURES)

  useEffect(() => {
    if (showBenefitAdditionaInfo) {
      const event = createEvent({
        GA: {
          category: 'Show more benefit details',
          action: 'Select',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'Show more benefit details',
          },
        },
      })
      event.end()
    }
  }, [showBenefitAdditionaInfo])

  useEffect(() => {
    const event = createEvent({
      GA: {
        category: 'Advisor client policy details',
        action: 'View benefits',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'View benefits',
        },
      },
    })
    event.end()
  }, [])

  const masterList = useSelector(getMasterData)
  const benefitExtensionText = useSelector(state =>
    getExtendedBenefitLabel(state, {
      benefit: benefitData,
      bancsPolicyNo,
      benefitExtensionLabel: fields.BenefitExtensionLabel,
    })
  )
  const { connectedBenefitLabel, isConnectedBenefitLinkDisabled, parentPolicyIndex } = useSelector(
    state =>
      makeConnectedBenefitData(state, {
        benefit: benefitData,
        bancsPolicyNo,
        connectedBenefitLabel: fields.ConnectedBenefitLabel,
      })
  )

  const onConnectedBenefitClick = useCallback(() => {
    onPolicyTabChange(parentPolicyIndex)
  }, [parentPolicyIndex])

  const renderListItemWithTooltip = ({ label, value, tooltipContent }: ItewWithTooltipProps) => {
    const tooltipID = idMaker(get(label, 'value', ''))
    return (
      <ListItemWithTooltip>
        <span>{renderTextField(label)}</span>
        <div>
          <div data-tip data-for={tooltipID}>
            <Icon iconName={['far', 'circle-info']} />
          </div>
          <TooltipContent id={tooltipID} multiline>
            {renderTextField(tooltipContent, true)}
          </TooltipContent>
        </div>
        <span>{`: ${value}`}</span>
      </ListItemWithTooltip>
    )
  }

  const renderBenefitAdditionalInfo = (benefitInfo: benefitType) => {
    const { features = [], subBenefits = [], benefitAssured } = benefitInfo
    const {
      BenefitFeatures,
      BenefitOptions,
      Exclusions,
      Loadings,
      DiscountApplied,
      VivoDiscount,
      MCDDiscount,
      NoItemAvailable,
      VivoDiscountTooltip,
      MCDDiscountTooltip,
    } = fields

    const benefitExclusions = (benefitAssured as benefitAssuredType[]).flatMap(({ exclusions }) =>
      (exclusions || [])
        .filter(({ exclusionPara }) => exclusionPara)
        .map(exclusion => exclusion.exclusionPara)
    )

    const benefitLoadings = (benefitAssured as benefitAssuredType[]).flatMap(({ loadings }) =>
      (loadings || []).map(loading => {
        const { value, reasonDescription } = getBenefitLoadingsDescription(loading) as {
          value: string
          reasonDescription: string
        }
        return `${value} for ${reasonDescription}`
      })
    )

    const getDiscountNodes = (): React.ReactNode[] | [] => {
      const discountsNodesArr: React.ReactNode[] = []
      const vivoDiscount = healthyLivingDiscountVal([benefitData]) as number
      const mcdDiscountVal = getDiscountVal(MULTI_COVER_DISCOUNT, benefitData.discounts)

      vivoDiscount > 0 &&
        discountsNodesArr.push([
          renderListItemWithTooltip({
            label: VivoDiscount,
            value: `${vivoDiscount}%`,
            tooltipContent: VivoDiscountTooltip,
          }),
        ])

      // business wants MCD only being applied to serries 6 or later and not IP benefit
      ;+(legacySystemProductCode?.split(' ')[1]?.match(/\d+/)?.[0] ?? 0) >= 6 &&
        benefitData.type !== INCOME_PROTECTION_ID &&
        mcdDiscountVal > 0 &&
        discountsNodesArr.push([
          renderListItemWithTooltip({
            label: MCDDiscount,
            value: `${mcdDiscountVal}%`,
            tooltipContent: MCDDiscountTooltip,
          }),
        ])

      return discountsNodesArr
    }

    const additionalInfoSections = [
      [Exclusions, benefitExclusions, 'bullet'],
      [Loadings, benefitLoadings, 'bullet'],
      [DiscountApplied, getDiscountNodes(), 'check'],
      [
        BenefitOptions,
        subBenefits
          .filter(({ subBenefitCode }) => !FEATURES_AS_SUB_BENEFITS.includes(subBenefitCode))
          .map(({ subBenefitName }) => subBenefitName),
        'check',
      ],
      [
        BenefitFeatures,
        [...features, ...(makeFeatureFromSubBenefits(subBenefits, features) as featureType[])].map(
          feature =>
            convertFeatureCodeToFullName(
              masterList,
              feature.featureName,
              feature.duration
            ) as string
        ),
        'check',
      ],
    ]
    return additionalInfoSections.map(additonalInfo => (
      <div>
        <Heading variant="h4">{renderTextField(additonalInfo[0])}</Heading>
        {(additonalInfo[1] as React.ReactNode[]).length > 0 ? (
          <List
            listItems={additonalInfo[1] as React.ReactNode[]}
            variant={additonalInfo[2] as 'bullet' | 'check'}
          />
        ) : (
          <p>
            {get(NoItemAvailable, 'value', '').replace(
              '{0}',
              (additonalInfo[0] as SitecoreField).value.toLowerCase()
            )}
          </p>
        )}
      </div>
    ))
  }

  const {
    benefit,
    benefitInstanceNo,
    benefitAssured,
    paymentStructure,
    sumInsured,
    premiumFrequency,
    premium,
    benefitExpiryDate,
    benefitCommencementDate,
    cpiStatus,
    type,
    waitingPeriod,
    coverPeriod,
    tpdDefinition,
    coverStyle,
  } = benefitData
  const lifeInsuredNameList = getLifeInsuredNameList(benefitAssured, relationships) as string[]
  const collectionFrequency = getCollectionFrequencyUnit(premiumFrequency)

  const {
    LifeInsured,
    SumInsured,
    Premium,
    Structure,
    StartDate,
    EndDate,
    CPIStatus,
    ShowMore,
    ShowLess,
    WaitingPeriod,
    Definition,
    OccupationRating,
    BenefitPeriod,
    BenefitStyle,
  } = reduceAuthorableFields(fields) as { [key: string]: string }

  return (
    <StyledContainer
      isAssociatedBenefit={isAssociatedBenefit}
      isFirstChild={isFirstChild}
      key={`${benefitInstanceNo}-${benefit}`}
    >
      <HeadingWithIcon
        iconName={['far', 'umbrella-simple']}
        headingContent={`${benefit} ${benefitInstanceNo}`}
        chipLabel={!isAssociatedBenefit ? benefitExtensionText : ''}
      />
      <GridContainer>
        <LabelValuePair label={LifeInsured} value={lifeInsuredNameList} />
        <LabelValuePair label={SumInsured} value={sumInsured} />
        {!isPolicyOutOfForce && (
          <LabelValuePair label={Premium} value={`${premium}/${collectionFrequency}`} />
        )}
        {!isAssociatedBenefit && (
          <LabelValuePair
            label={Structure}
            value={
              isCaliRelabel &&
              [POLICY_PRODUCT_CODE_SUPER, POLICY_PRODUCT_CODE_NON_SUPER].includes(
                productId as string
              ) &&
              paymentStructure === PREMIUM_STYLE_STEPPED
                ? NEW_STEPPED_LABEL
                : paymentStructure
            }
          />
        )}
        <LabelValuePair
          label={StartDate}
          value={
            benefitCommencementDate &&
            moment(benefitCommencementDate).format(DATE_FORMAT_WITH_MONTH_NAME)
          }
        />
        {!isPolicyOutOfForce && (
          <LabelValuePair
            label={EndDate}
            value={
              benefitExpiryDate && moment(benefitExpiryDate).format(DATE_FORMAT_WITH_MONTH_NAME)
            }
          />
        )}
        {!isAssociatedBenefit && (
          <>
            {[
              ...INCOME_PROTECTION_RELATED_POLICY_BENEFITS,
              ...BUSINESS_EXPENSE_RELATED_POLICY_BENEFITS,
            ].includes(type) && (
              <>
                <LabelValuePair label={WaitingPeriod} value={waitingPeriod} />
                <LabelValuePair label={BenefitPeriod} value={coverPeriod} />
                <LabelValuePair label={BenefitStyle} value={coverStyle} />
              </>
            )}
            <LabelValuePair label={CPIStatus} value={cpiStatus} />
            {POLICY_BENEFIT_PTD === type && (
              <LabelValuePair label={Definition} value={`${tpdDefinition as string} Occupation`} />
            )}
            {[
              ...INCOME_PROTECTION_RELATED_POLICY_BENEFITS,
              ...BUSINESS_EXPENSE_RELATED_POLICY_BENEFITS,
              POLICY_BENEFIT_PTD,
            ].includes(type) && (
              <LabelValuePair
                label={OccupationRating}
                value={get(benefitAssured, '[0].occupation.occupationRating', '') as string}
              />
            )}
          </>
        )}
      </GridContainer>
      {showBenefitAdditionaInfo && (
        <AdditonalInfoContainer>{renderBenefitAdditionalInfo(benefitData)}</AdditonalInfoContainer>
      )}
      {!isAssociatedBenefit && (
        <ActionLink
          onClick={() => setToggleBenefitInfo(!showBenefitAdditionaInfo)}
          label={showBenefitAdditionaInfo ? ShowLess : ShowMore}
        />
      )}
      {!isAssociatedBenefit && connectedBenefitLabel && (
        <ConnectedBenefitCTA
          onClick={onConnectedBenefitClick}
          label={connectedBenefitLabel}
          iconName={['far', 'link-horizontal']}
          disabled={isConnectedBenefitLinkDisabled}
        />
      )}
    </StyledContainer>
  )
}

export default Benefit
