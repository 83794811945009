// TODO: rename Tab to Tile as Tab is no longer relavant
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { pathOr } from 'lodash/fp'
import { RouteComponentProps } from 'react-router-dom'

// redux.
import { useSelector, useDispatch } from 'react-redux'

// components
import { Heading, Tooltip } from '@mlcl-digital/mlcl-design'
import Icon from '@mlcl-digital/mlcl-design/lib/base/Icon'
import InlineMessage from '@mlcl-digital/mlcl-design/lib/base/InlineMessage'
// @ts-expect-error file not in typescript
import WithLoader from '../WithLoader'
import { PolicyList } from './components/PolicyList'

// actions
// @ts-expect-error file not in typescript
import { actionCreators } from '../../../actions'

// styles.
import styles from './newTile.styles'

// utils
// @ts-expect-error file not in typescript
import history from '../../../utils/browserHistory'
import { reduceAuthorableFields } from '../../../utils/sitecoreUtils'
import { shouldShowWarning } from './utils'
// @ts-expect-error file not in typescript
import { getPolicyDetailsByMemberId, getPolicyIDBySearchInput } from '../../../utils/policyUtils'
import { RetentionPolicy } from '../../../types/retentionDashboard'

// selectors
import {
  getLapsedPoliciesWithinLast30Days,
  getMissedPaymentPoliciesWithinNext15days,
} from '../../../selectors/retention'
import {
  TOAST_ID_RETENTION_POLICY_ERROR,
  TOAST_RETENTION_CALL_POLICY_FAIL,
} from '../../../constants/toast'
import { getMasterData } from '../../../selectors/common.selectors'

// types
import { RETENTION_CATEGORIES } from '../../../types/retention'
import { PolicyActivityFields } from '../../../types/components/AdvisorDashboard'
import { Store } from '../../../types/store'
import { ClientPolicy } from '../../../types/ClientPolicies'

const TabWrapper = styled('div')(styles.tabWrapper)
const Tabs = styled('div')(styles.tabs)
const TabContent = styled('div')(styles.tabContent)
const TileWrapper = styled('div')(styles.tileWrapper)
const TabValue = styled(Heading)(styles.tabValue)
const TabTitle = styled('p')(styles.tabTitle)
const TileContent = styled('div')(styles.tileContent)
const StyledToolTip = styled(Tooltip)(styles.toolTip)
const StyledIcon = styled(Icon)(styles.infoIcon)

type TileProps = {
  schema: {
    label: string
    id: RETENTION_CATEGORIES
    value: number
    message: string
  }[]
  dashboardDetailLimit: number
  fields: PolicyActivityFields
  onClick: null | ((id: string) => void)
  href: string
  allowNavigation: boolean
  activeTab: string
  showLoader: boolean
  showLoaderProps: { isLoading: boolean }
  setEmailClientErrorModalRedirectID: () => void
  showEmailClientErrorModal: () => void
}

const Tile = (props: TileProps) => {
  const {
    schema,
    dashboardDetailLimit,
    fields,
    onClick,
    href,
    allowNavigation,
    showLoader,
    showLoaderProps,
    setEmailClientErrorModalRedirectID,
    showEmailClientErrorModal,
  } = props
  const isLoading = pathOr(false, 'isLoading', showLoaderProps)

  const dispatch = useDispatch()

  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const [policyDataFetching, setPolicyDataFetching] = useState(false)

  // Selectors
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { hasFetchError: isSearchPolicyFetchError } = useSelector(
    (state: Store) => state.searchPolicy
  )
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { hasFetchError: isPoliciesFetchError } = useSelector((state: Store) => state.policies)
  const { policyStatus = [] } = useSelector(getMasterData)

  const isSearchPoliciesAndPoliciesFetchError = Boolean(
    isSearchPolicyFetchError || isPoliciesFetchError
  )

  const showPolicyList = schema.some(
    ({ id, value }) =>
      [RETENTION_CATEGORIES.LAPSED, RETENTION_CATEGORIES.MISSED_PAYMENTS].includes(id) && value > 0
  )

  // types
  type Policy = {
    policyNo: string
    bancsPolicyNo: string
    isSMSF: boolean
    relations: [{ roles: string[]; isPrimaryPolicyOwner?: boolean; bancsPartyNo: string }]
  }

  type PoliciesResponse = { businessData: { policies: ClientPolicy[] } }

  const { lapsedTileCardContent, missedPaymentCardContent, gracePeriodToolTip } =
    reduceAuthorableFields(fields)

  const showPolicyCallFailureToast = () =>
    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
    toast(TOAST_RETENTION_CALL_POLICY_FAIL, {
      autoClose: 3000,
      toastId: TOAST_ID_RETENTION_POLICY_ERROR,
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      type: toast.TYPE.ERROR,
    })

  const getAdviserPolicy = (bancsPartyNo: string, isSMSF: boolean, policyNo: string) => {
    dispatch(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      actionCreators.getPolicies(bancsPartyNo, isSMSF, (data: PoliciesResponse) => {
        setPolicyDataFetching(false)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (!data?.businessData) {
          showPolicyCallFailureToast()
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const policyId = getPolicyIDBySearchInput(
            data?.businessData?.policies,
            policyStatus,
            policyNo
          )
          if (!policyId) {
            showPolicyCallFailureToast()
          } else {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            const path = `/clients/details?clientId=${bancsPartyNo}&policyId=${policyId}&isSMSF=${isSMSF}&fromSearch=true`
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            history.push(path)
          }
        }
      })
    )
  }

  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unused-vars, @typescript-eslint/no-unsafe-call
  const getPolicyDetailsNRedirect: (policyNo: string) => void = policyNo => {
    if (policyNo) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      setPolicyDataFetching(true)
      dispatch(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        actionCreators.searchPolicies(
          policyNo,
          (
            err: Error,
            data: {
              businessData: { policies: Policy[]; totalNoOfPolicies: number }
              status: { code: string; description: string; type: string }
            }
          ) => {
            if (err) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              setPolicyDataFetching(false)
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              showPolicyCallFailureToast()
            } else {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              getPolicyDetailsByMemberId(data, getAdviserPolicy, policyNo)
            }
          }
        )
      )
    }
  }

  const ToolTip = () => (
    <>
      <span data-tip data-for="gracePeriodTooltip">
        <StyledIcon iconName={['far', 'circle-info']} />
      </span>
      <StyledToolTip id="gracePeriodTooltip" place="right">
        {gracePeriodToolTip}
      </StyledToolTip>
    </>
  )

  // RET-19185: Tile Config for Lapsed and Missed Payment tiles only as per business needs today
  const tileConfig: { [key: string]: { tableData: RetentionPolicy[]; cardInfo: string } } = {
    [RETENTION_CATEGORIES.LAPSED]: {
      tableData: useSelector(getLapsedPoliciesWithinLast30Days),
      cardInfo: `${
        useSelector(getLapsedPoliciesWithinLast30Days)?.length
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      } ${lapsedTileCardContent}`,
    },
    [RETENTION_CATEGORIES.MISSED_PAYMENTS]: {
      tableData: useSelector(getMissedPaymentPoliciesWithinNext15days),
      cardInfo: `${
        useSelector(getMissedPaymentPoliciesWithinNext15days)?.length
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      } ${missedPaymentCardContent}`,
    },
  }

  useEffect(() => {
    showPolicyList &&
      dispatch(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        actionCreators.getRetentionDashboard({}, dashboardDetailLimit)
      )
  }, [showPolicyList])

  const checkNavigation = (id: string) => {
    if (href) {
      return `${href}#${id}`
    }
    return `#${id}`
  }

  const renderTabValue = (value: number) => (
    <TabValue variant="h2" size="XL">
      {value}
    </TabValue>
  )

  const renderTabs = (label: string, id: RETENTION_CATEGORIES, value: number, message: string) => (
    <Tabs data-testid={`${id}-tile`} key={id}>
      <TileWrapper
        onClick={() =>
          allowNavigation
            ? (history as RouteComponentProps['history']).push(checkNavigation(id))
            : null
        }
      >
        <TabContent onClick={() => onClick && onClick(id)}>
          {showLoader ? (
            <WithLoader isLoading={isLoading}>{renderTabValue(value)}</WithLoader>
          ) : (
            renderTabValue(value)
          )}
          <TabTitle>{label}</TabTitle>
        </TabContent>
        {shouldShowWarning(id, value) && (
          <div>
            <InlineMessage iconName={['far', 'shield-exclamation']} message={message} />
          </div>
        )}
      </TileWrapper>
      {tileConfig[id]?.tableData?.length > 0 && shouldShowWarning(id, value) && (
        <>
          <TileContent>
            <>
              {tileConfig[id]?.cardInfo} {[RETENTION_CATEGORIES.LAPSED].includes(id) && <ToolTip />}
            </>
          </TileContent>
          <WithLoader
            isLoading={policyDataFetching && !isSearchPoliciesAndPoliciesFetchError}
            overlay
          >
            <PolicyList
              fields={fields}
              policies={tileConfig[id]?.tableData}
              rowClickHandler={getPolicyDetailsNRedirect}
              id={
                id as Exclude<
                  RETENTION_CATEGORIES,
                  RETENTION_CATEGORIES.CANCELLED | RETENTION_CATEGORIES.POLICY_ANNIVERSARY
                >
              }
              setEmailClientErrorModalRedirectID={setEmailClientErrorModalRedirectID}
              showEmailClientErrorModal={showEmailClientErrorModal}
            />
          </WithLoader>
        </>
      )}
    </Tabs>
  )

  return (
    <TabWrapper>
      {schema.map(({ label, id, value, message }) => renderTabs(label, id, value, message))}
    </TabWrapper>
  )
}

Tile.defaultProps = {
  onClick: null,
  activeTab: null,
  fields: {},
}

Tile.propTypes = {
  schema: PropTypes.arrayOf(PropTypes.object).isRequired,
  dashboardDetailLimit: PropTypes.number.isRequired,
  fields: PropTypes.shape({
    fields: PropTypes.shape({
      policyOwner: PropTypes.string,
      paymentOutstandingAmount: PropTypes.string,
      emailCTA: PropTypes.string,
      showAllText: PropTypes.string,
      showLessText: PropTypes.string,
      lapsedTileCardContent: PropTypes.string,
      missedPaymentCardContent: PropTypes.string,
      gracePeriodToolTip: PropTypes.string,
    }),
  }),
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  allowNavigation: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool.isRequired,
  showLoaderProps: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  activeTab: PropTypes.string,
}

export default Tile
export { Tile }
