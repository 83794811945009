// @FIXME: need to remove eslint disables once files get changed to typesciprt
import React, { useRef, useState, useEffect } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'

// components
import { Table, Button } from '@mlcl-digital/mlcl-design'

// utils
import { renderTextField } from '../../../../../utils/sitecoreUtils'
// @ts-expect-error file not in typescript
import { dollarAmountWithCommasAndTwoDecimal } from '../../../../../utils/formUtils'
// @ts-expect-error file not in typescript
import { actionCreators } from '../../../../../actions'
import { getPreferredItemBreakdown } from '../../../../../utils/contactUtils'
// @ts-expect-error file not in typescript
import { allOccuranceReplace } from '../../../../../utils/commonUtils'
import { getConfig } from '../../../../../selectors/common.selectors'
import { isFeatureEnabled } from '../../../../../utils/featureToggling'
// @ts-expect-error file not in typescript
import { sortDateGeneric } from '../../../../../utils/sortingUtils'
import { createEvent } from '../../../../../utils/telemetry'

// styles
import styles from './policyList.styles'

// constants
import {
  BANCS_CUSTOMER_NUMBER_NULIS,
  BANCS_CUSTOMER_NUMBER_IOOF,
} from '../../../../../constants/bancs'
import { POLICY_MEMBER_IDENTIFIER_PARTY } from '../../../../../constants/policies'

// types
import { RetentionPolicy } from '../../../../../types/retentionDashboard'
import { PolicyActivityFields } from '../../../../../types/components/AdvisorDashboard'
import { MembersDetails } from '../../../../../types/members'
import { RETENTION_CATEGORIES } from '../../../../../types/retention'

type Original = {
  policyOwner: string
  paymentOutstandingAmount: string
  policyNo: string
}

type RowInfo = {
  original: Original
}

type PolicyListProps = {
  fields: PolicyActivityFields
  id: Exclude<
    RETENTION_CATEGORIES,
    RETENTION_CATEGORIES.CANCELLED | RETENTION_CATEGORIES.POLICY_ANNIVERSARY
  >
  setEmailClientErrorModalRedirectID: (clientId: string, policyId: string) => void
  showEmailClientErrorModal: () => void
  policies?: RetentionPolicy[]
  rowClickHandler: (policyNo: string) => void
}

const EmailClientBtn = styled(Button)({
  '& >a': {
    color: 'inherit',
    textDecoration: 'inherit',
  },
})

const formatTableRecords = (policies: RetentionPolicy[]) =>
  (policies?.length > 0 &&
    policies.map(
      ({
        policyOwner,
        paymentOutstandingAmount,
        bancsPolicyNo,
        policyNo,
        lapseDate,
        scheduledLapseDate,
      }) => ({
        policyOwner: policyOwner?.fullName,
        paymentOutstandingAmount: dollarAmountWithCommasAndTwoDecimal(paymentOutstandingAmount),
        bancsPolicyNo,
        policyNo,
        lapseDate,
        scheduledLapseDate,
      })
    )) ||
  []

export const PolicyList = ({
  fields,
  policies: allPolicies,
  id,
  rowClickHandler,
  setEmailClientErrorModalRedirectID,
  showEmailClientErrorModal,
}: PolicyListProps) => {
  const { showAllText, showLessText } = fields
  const { getClientDetails } = actionCreators
  const [textToggle, setTextToggle] = useState<boolean>(false)
  const [preferredEmail, setPreferredEmail] = useState<string>('')
  const [emailClientContent, setEmailClientContent] = useState<string>('')
  const POLICY_SORT_MAP = {
    [RETENTION_CATEGORIES.MISSED_PAYMENTS]: 'scheduledLapseDate',
    [RETENTION_CATEGORIES.LAPSED]: 'lapseDate',
  } as const
  const emailClientRef = useRef<HTMLAnchorElement>(null)
  const formattedPolicies = formatTableRecords(allPolicies ?? [])
    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
    .sort(sortDateGeneric(POLICY_SORT_MAP?.[id], 'policyOwner', true))
  const policies = textToggle ? formattedPolicies : formattedPolicies?.slice(0, 11)
  const arePoliciesMoreThanTen = (policies?.length ?? -1) > 10
  const dispatch = useDispatch()

  useEffect(() => {
    if (emailClientContent) {
      emailClientRef.current && emailClientRef.current.click()
    }
    setPreferredEmail('')
    setEmailClientContent('')
  }, [emailClientContent])

  const handleEmailClientClick = async (
    clientBancsCustNo: string,
    bancsPolicyNo: string,
    emailContent: string,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation()
    if (!preferredEmail) {
      e.preventDefault()
    } else {
      return
    }

    const eventInfoMapping = {
      [RETENTION_CATEGORIES.LAPSED]: {
        category: 'Dashboard email client Lapsed policies',
        action: 'AP - lapsed policies select client email',
      },
      [RETENTION_CATEGORIES.MISSED_PAYMENTS]: {
        category: 'Dashboard email client Missed payment',
        action: 'AP - missed payment select client email',
      },
    }
    const event = createEvent({
      GA: eventInfoMapping[id],
      Splunk: {
        attributes: {
          'workflow.name': eventInfoMapping[id].category,
        },
      },
    })
    event.end()

    setEmailClientErrorModalRedirectID(clientBancsCustNo, bancsPolicyNo)

    if (!preferredEmail) {
      await dispatch(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        getClientDetails(clientBancsCustNo, (dataReceived: MembersDetails) => {
          const clientEmailsArr = dataReceived?.businessData?.member?.contactMethods?.emails ?? []
          if (clientEmailsArr.length > 0) {
            // @ts-expect-error need to resolve getPreferredItemBreakdown util function
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            setPreferredEmail(getPreferredItemBreakdown(clientEmailsArr, null)?.email as string)
            setEmailClientContent(
              allOccuranceReplace(emailContent, {
                '{4}': dataReceived?.businessData?.member?.identifiers?.find(
                  identifier => identifier?.type === POLICY_MEMBER_IDENTIFIER_PARTY
                )?.value,
              })
            )
          } else {
            showEmailClientErrorModal()
          }
        })
      )
    }
  }

  // eslint-disable-next-line no-shadow
  const columns = (fields: PolicyActivityFields) => [
    {
      Header: renderTextField(fields.policyOwner),
      id: 'policyOwner',
      accessor: 'policyOwner',
      disableSortBy: true,
    },
    {
      Header: renderTextField(fields.paymentOutstandingAmount),
      id: 'paymentOutstandingAmount',
      accessor: 'paymentOutstandingAmount',
      disableSortBy: true,
    },
    {
      Header: '',
      id: 'action',
      accessor: 'action',
      disableSortBy: true,
      ...(isFeatureEnabled('AdvisorDashboardEmailClient', useSelector(getConfig).FEATURES) && {
        Cell: ({
          row: {
            original: {
              policyOwner,
              policyNo,
              lapseDate,
              scheduledLapseDate,
              bancsPolicyNo,
              paymentOutstandingAmount,
            },
          },
        }: {
          row: {
            original: {
              policyOwner: string
              policyNo: string
              lapseDate: Date
              paymentOutstandingAmount: number
              scheduledLapseDate: Date
              bancsPolicyNo: string
            }
          }
        }) => {
          const matchedPolicy = allPolicies?.find(policy => policy?.bancsPolicyNo === bancsPolicyNo)
          const isOwnerNULISorIOOF = [
            BANCS_CUSTOMER_NUMBER_NULIS,
            BANCS_CUSTOMER_NUMBER_IOOF,
          ].includes(matchedPolicy?.policyOwner?.bancsCustomerNo ?? '')
          const clientBancsCustNo = !isOwnerNULISorIOOF
            ? matchedPolicy?.policyOwner?.bancsCustomerNo ?? ''
            : matchedPolicy?.lifeAssured?.[0]?.bancsCustomerNo ?? ''
          const EMAIL_CONTENT_MAP = {
            [RETENTION_CATEGORIES.MISSED_PAYMENTS]: 'missedPaymentEmailContent',
            [RETENTION_CATEGORIES.LAPSED]: 'policyLapsedEmailContent',
          } as const
          const scemailTemplate = fields[EMAIL_CONTENT_MAP[id]]?.value ?? ''
          const emailContent = allOccuranceReplace(scemailTemplate, {
            '{0}': isOwnerNULISorIOOF
              ? `${matchedPolicy?.lifeAssured?.[0]?.firstName ?? ''} ${
                  matchedPolicy?.lifeAssured?.[0]?.lastName ?? ''
                }`
              : policyOwner,
            '{1}': policyNo,
            '{2}': paymentOutstandingAmount,
            '{3}':
              id === RETENTION_CATEGORIES.LAPSED
                ? moment(lapseDate, 'DD/MM/YYYY').add(30, 'days').format('Do MMMM YYYY')
                : moment(scheduledLapseDate, 'DD/MM/YYYY').format('Do MMMM YYYY'),
          })
          return (
            <div>
              <EmailClientBtn
                variant="secondaryWithTheme"
                size="small"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                  handleEmailClientClick(clientBancsCustNo, bancsPolicyNo, emailContent, e)
                }
              >
                <a
                  ref={emailClientRef}
                  href={`mailto:${preferredEmail}?body=${emailClientContent}`}
                >
                  {renderTextField(fields.emailCTA)}
                </a>
              </EmailClientBtn>
            </div>
          )
        },
      }),
    },
  ]

  const rowClick = (rowInfo: RowInfo) => {
    const rowEventInfoMapping = {
      [RETENTION_CATEGORIES.LAPSED]: {
        category: 'Lapsed policies adviser dashboard client',
        action: 'AP - lapsed policies select client',
      },
      [RETENTION_CATEGORIES.MISSED_PAYMENTS]: {
        category: 'Missed payment adviser dashboard client',
        action: 'AP - missed payment select client',
      },
    }
    const event = createEvent({
      GA: rowEventInfoMapping[id],
      Splunk: {
        attributes: {
          'workflow.name': rowEventInfoMapping[id].category,
        },
      },
    })
    event.end()
    rowInfo?.original?.policyNo && rowClickHandler(rowInfo?.original?.policyNo)
  }

  const columnsToShow = ['policyOwner', 'paymentOutstandingAmount', 'action']

  return (
    <Table
      columns={columns(fields).filter(column => columnsToShow.includes(column.id))}
      data={policies}
      clickHandler={rowClick}
      pageSize={1000}
      isLoading={false}
      styleOverrides={styles.styleOverrides(
        arePoliciesMoreThanTen && !textToggle,
        policies?.length
      )}
      footer={
        arePoliciesMoreThanTen && (
          <Button variant="secondaryWithTheme" onClick={() => setTextToggle(!textToggle)}>
            {textToggle ? renderTextField(showLessText) : renderTextField(showAllText)}
          </Button>
        )
      }
    />
  )
}

PolicyList.propTypes = {
  fields: PropTypes.shape({
    policyOwner: PropTypes.shape({
      value: PropTypes.string,
    }),
    paymentOutstandingAmount: PropTypes.shape({
      value: PropTypes.string,
    }),
    emailCTA: PropTypes.shape({
      value: PropTypes.string,
    }),
  }).isRequired,
  policies: PropTypes.arrayOf(
    PropTypes.shape({
      policyOwner: PropTypes.shape({
        fullName: PropTypes.string,
      }),
      paymentOutstandingAmount: PropTypes.string,
    })
  ).isRequired,
  rowClickHandler: PropTypes.func.isRequired,
}
export default PolicyList
