/**
 * Utility function to insert records within an array. The records
 * are inserted into a shallow clone of the original array, and
 * the function parameters are not altered.
 *
 * @template T, U any types to use in the resulting array
 * @param recordsToInsert array of records to insert
 * @param originalArray original array to be used to insert
 * @param arrayIndexInsertPosition position starting from 0
 * @returns new array with records inserted
 */
export function insertRecordInArrayAtPosition<T, U>(
  recordsToInsert: readonly T[],
  originalArray: readonly U[],
  arrayIndexInsertPosition: number
): (T | U)[] {
  const arrayClone: (T | U)[] = [...originalArray]
  arrayClone.splice(arrayIndexInsertPosition, 0, ...recordsToInsert)
  return arrayClone
}
