// @flow
import { Variables } from '@mlcl-digital/mlcl-design'
import { colours, space, fontSize, fontWeights, mediaQueries } from '../../../styles'

const { colours: colour } = Variables

const styles = {
  wrapper: ({ applicationView }) => ({
    marginTop: applicationView ? 0 : space(8, true),
  }),
  wrap: {
    '&.applicationView': {
      padding: 0,
    },
  },
  policyContainer: {
    background: colours.white,
    padding: space(4),
    marginBottom: space(5),
  },
  policyHeader: {
    display: 'flex',
    borderBottom: `1px solid ${colours.lightGrey}`,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    color: colours.black,
    paddingBottom: space(2),
    flexDirection: 'column',

    [mediaQueries.md]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  policyBody: {
    marginTop: space(2),
  },
  policyNameDetail: {
    fontSize: fontSize.sm,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
  },
  policyType: isPaddingRequired => ({
    marginLeft: space(2),
    padding: isPaddingRequired ? '2px 15px' : '2px 4px',
    ...(isPaddingRequired && {
      lineHeight: 1.4,
    }),
  }),

  stickyCTAs: isSticky => ({
    background: colours.darkGreen,
    position: 'absolute',
    bottom: 'auto',
    marginTop: space(9),
    left: 0,
    right: 0,
    ...(isSticky && {
      position: 'fixed',
      bottom: 0,
    }),
  }),

  policyPremiumDetail: {
    margin: 0,
    fontSize: fontSize.xs,
    fontWeight: fontWeights.semiBold,
    display: 'flex',
    alignItems: 'center',

    '& span': {
      fontSize: fontSize.sm,
      marginLeft: space(2),
    },
  },
  coverHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: colours.darkGrey,
    marginBottom: space(1),
    fontWeight: fontWeights.semiBold,
    fontSize: fontSize.xs,
  },
  coverNameDetail: {
    margin: 0,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  coverHighLevelResult: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
  },
  decisionList: {
    padding: 0,
    width: '60%',
    margin: `0 ${space(4, true)} ${space(2, true)} ${space(4, true)}`,
  },
  decisionItem: {},
  stickyWrapper: {
    minHeight: space(19),
    width: '100%',
  },
  CTAList: {
    listStyle: 'none',
    padding: `${space(2, true)} 0`,
    margin: 0,
    textAlign: 'right',
  },
  individualCTA: {
    display: 'inline-block',
  },
  modalContent: {
    color: colours.black,
    minHeight: space(10, true),
    marginBottom: space(2),
  },
  sectionHeaderWrap: {
    padding: `${space(1, true)} ${space(0, true)} ${space(12, true)}`,
    margin: 0,
  },
  childName: {
    fontSize: fontSize.xxs,
  },
  okButton: {
    width: space(16, true),
    marginTop: space(3),
  },
  halfWidth: {
    flex: '0 1 35%',
    width: '50%',
    marginTop: space(2, true),
  },
  decisionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tag: {
    verticalAlign: 'text-bottom',
    margin: `0 ${space(0.5, true)}`,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  featureName: {
    fontSize: fontSize.xxs,
    paddingLeft: space(1, true),
  },
  decisionItemWithCheckbox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignContent: 'center',
    border: `3px solid ${colour.tertiaryThree}`,
    backgroundColor: colours.offWhite,
    margin: `${space(2, true)} ${space(2, true)}`,
    padding: '10px',
  },
  customCheckBox: {
    label: {
      color: colours.darkGrey,
      paddingTop: '3px',
      '&:before': {
        boxShadow: `inset 0 0 0 1px ${colours.darkGrey}`,
      },
    },
    '&:checked + label': {
      color: 'white',
      '&:before': {
        background: '#016A45',
        boxShadow: 'none',
      },
    },
    '&:checked + label svg': {
      color: colours.white,
      width: '16px',
      top: '10px',
      height: '16px',
    },
  },
  decisionItemWithCheckboxChecked: {
    backgroundColor: '#016A45',
    border: '3px solid #016A45',
  },
  continueButton: {
    '&:disabled': {
      backgroundColor: colour.mlcAquaAccessible,
      color: colour.tertiarySix,
      opacity: 0.25,
    },
  },
  licopContinueButton: {
    '& > div': {
      width: space(8.75),
    },
  },
}
export default styles
