// @flow
/* eslint-disable no-param-reassign */
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from '@emotion/styled'
import get from 'lodash/get'

// actions
import { actionCreators } from '../../../../../actions'

// component
import RadioGroup from '../../../../molecules/RadioGroup'
import Input from '../../../../atoms/Input'

// constants
import Schema, { FORM_ID, DEFAULTS, PRIMARY, OTHER } from './followUpContactForm.schema'
import { errorCheck } from '../../../../../utils/formUtils'
import { renderTextField, reduceAuthorableFields } from '../../../../../utils/sitecoreUtils'

// styles
import styles from './followUpContactForm'

// constants
import { POLICY_RELATIONSHIPS_LIFEASSURED, PREFERRED_YES } from '../../../../../constants/policies'

type UnderWritingProps = {
  actions: Object,
  form: Object,
  isTrackFollowupAgreed: boolean,
  fields: Object,
  createQuote: { quotes: Object, activeIndex: string },
}

const HalfWidthInput = styled(Input)(styles.select)
const ContactWrapper = styled.div(styles.contactWrapper)
const ContactRadioGroup = styled.div(styles.contactRadioGroup)

function getMemberDetails(policy) {
  return (
    policy &&
    policy.relationships &&
    policy.relationships.find(member =>
      member.role.find(role => role.includes(POLICY_RELATIONSHIPS_LIFEASSURED))
    )
  )
}

export class FollowUpContactFormComponent extends React.Component<UnderWritingProps> {
  constructor(props) {
    super(props)
    this.state = {
      contactNumber: '',
    }
  }

  componentWillMount() {
    const {
      actions,
      form,
      fields,
      createQuote: { quotes, activeIndex },
    } = this.props
    const { policyStructure, fastTrackingFollowupInfo = {} } = quotes[activeIndex]
    const { preferred = [], alternateContact } = fastTrackingFollowupInfo
    const relatedMember = getMemberDetails(policyStructure[0])
    const relatedParty = relatedMember && relatedMember.relatedParty
    const primaryPhoneIndex =
      relatedParty &&
      relatedParty.contactMethods.phones.findIndex(phone => phone.preferred === PREFERRED_YES)
    const contactNumber = get(relatedParty, `contactMethods.phones[${primaryPhoneIndex}].number`)
    const SCHEMA = Schema(reduceAuthorableFields(fields))
    this.setState({
      contactNumber,
    })
    const { formInit } = actions
    if (!form) formInit(FORM_ID, SCHEMA, DEFAULTS(preferred, alternateContact))
  }

  handlePhoneNumberSelection = value => {
    const { actions, fields } = this.props
    const { formUpdateField } = actions
    const SCHEMA = Schema(reduceAuthorableFields(fields), value)

    const alternateContactOption = {
      error: errorCheck(value, SCHEMA.alternateContactOptions.condition),
      value,
    }
    formUpdateField(FORM_ID, 'alternateContactOptions', alternateContactOption)
    if (value === PRIMARY) {
      const alternateContact = {
        error: errorCheck('', SCHEMA.alternateContact.condition),
        value: '',
      }
      formUpdateField(FORM_ID, 'alternateContact', alternateContact)
    }
  }

  handleInputChange = ({ value }) => {
    const { actions, fields, form } = this.props
    const { formUpdateField } = actions
    const {
      fields: { alternateContactOptions },
    } = form
    const SCHEMA = Schema(reduceAuthorableFields(fields), alternateContactOptions.value)

    const alternateContact = {
      error: errorCheck(value, SCHEMA.alternateContact.condition),
      value,
    }
    formUpdateField(FORM_ID, 'alternateContact', alternateContact)
  }

  render() {
    const { isTrackFollowupAgreed, fields, form } = this.props
    if (!form) return null
    const { contactNumber } = this.state
    const {
      fields: { alternateContact, alternateContactOptions },
    } = form
    if (!isTrackFollowupAgreed || isTrackFollowupAgreed === 'no') return null
    const {
      personalStatementFastTrackContactNumberHeading,
      radioGroupFastTrackingContactNumberOptionOther,
    } = fields
    const reducedFields = reduceAuthorableFields(fields)
    const {
      radioGroupFastTrackingContactNumberOptionPrimary,
      personalStatementFastTrackContactNumberLabel,
    } = reducedFields
    const radioGroupFastTrackingContactNumberOptions = [
      {
        key: PRIMARY,
        text: renderTextField({
          value: `${radioGroupFastTrackingContactNumberOptionPrimary} ${contactNumber || ''}`,
        }),
        value: PRIMARY,
      },
      {
        key: OTHER,
        text: renderTextField(radioGroupFastTrackingContactNumberOptionOther),
        value: OTHER,
      },
    ]
    return (
      <React.Fragment>
        <ContactWrapper>
          {renderTextField(personalStatementFastTrackContactNumberHeading, true)}
          <ContactRadioGroup>
            <RadioGroup
              options={radioGroupFastTrackingContactNumberOptions}
              selectedItem={alternateContactOptions.value}
              handleChange={this.handlePhoneNumberSelection}
              name="followUpFastTrackContactNumber"
              error={
                alternateContactOptions.error &&
                alternateContactOptions.error.error &&
                alternateContactOptions.error.errorMsg
              }
            />
          </ContactRadioGroup>
          {alternateContactOptions.value === OTHER ? (
            <HalfWidthInput
              htmlFor="contactNumber"
              name="contactNumber"
              label={personalStatementFastTrackContactNumberLabel}
              changeHandler={this.handleInputChange}
              error={alternateContact.error.error}
              caption={alternateContact.error.error && alternateContact.error.errorMsg}
              value={alternateContact.value}
            />
          ) : (
            ''
          )}
        </ContactWrapper>
      </React.Fragment>
    )
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export const mapStateToProps = ({ forms, createQuote }) => ({
  form: forms[FORM_ID],
  createQuote,
})

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpContactFormComponent)
