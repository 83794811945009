// @flow
import get from 'lodash/get'
import { toast } from 'react-toastify'
import moment from 'moment'
import { uuidv4 } from '../../utils/commonUtils'
import {
  EXPERIENCE_API_VERSION_1,
  EXPERIENCE_API_BASE,
  SITECORE_API_BASE,
} from '../../constants/apis'
import { ADVISER_NO_KEY } from '../../constants/adviser'
import {
  RETRIEVE_ADVISOR_LIST_FOR_SUPPORT_STAFF,
  SAVE_SUPPORT_STAFF_CONSENT,
  SAVE_ACTIVE_SUPPORT_STAFF_DATA,
  SAVE_ACTIVE_ADVISER_DATA,
  FETCH_SUPPORT_STAFF_DETAILS,
  RESET_ADVISOR_SUPPORT_STAFF,
  UPDATE_SUPPORT_STAFF_DETAILS,
} from '../types/supportStaff'
import { EXPERIENCE_API } from '../types/experienceApi'
import { SITECORE_API } from '../types/sitecoreApi'
import { getBancsCustomerNumber } from '../../utils/cookieUtils'
import { sortAgencies } from '../../utils/supportStaffUtils'
import { callExternalAPIv2 } from '../../utils/customerPortalExperienceAPIUtils'
import { buildSupportStaffOnboardPayload } from '../../utils/formUtils'
import {
  TOAST_TITLE_ONBOARD_SUPPORT_STAFF_ERROR,
  TOAST_TITLE_ONBOARD_SUPPORT_STAFF_SUCCESS,
  TOAST_ID_ONBOARD_SUPPORT_STAFF,
} from '../../constants/toast'

type SaveSupportStaffPayloadType = {
  advsupportstaffid: String,
  hasacceptedterms: String,
  name: String,
  version: String,
}

export const retrieveAdviserForSupportStaff =
  (payload: Object, callback: Function = () => {}) =>
  dispatch => {
    const { bancsCustomerNo } = payload

    dispatch({
      type: EXPERIENCE_API,
      name: RETRIEVE_ADVISOR_LIST_FOR_SUPPORT_STAFF,
      verb: 'GET',
      removeHeaders: [ADVISER_NO_KEY],
      // eslint-disable-next-line max-len
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/supportStaff/relationship/search/${bancsCustomerNo}`,
      callback: (err, dataReceived) => {
        if (!err && dataReceived) {
          callback({ ...dataReceived })
          const businessData = get(dataReceived, 'businessData', {})
          const agencies = get(businessData, 'agencies')
          return {
            data: {
              ...businessData,
              ...(agencies ? { agencies: sortAgencies(agencies) } : null),
            },
          }
        }
        return dataReceived
      },
    })
  }

export const saveSupportStaffConsent =
  (data: SaveSupportStaffPayloadType, callback?: Function) => (dispatch, getState) => {
    const {
      config: { MLCL_SITECORE_CMS_KEY },
    } = getState()
    dispatch({
      type: SITECORE_API,
      name: SAVE_SUPPORT_STAFF_CONSENT,
      verb: 'POST',
      route: `${SITECORE_API_BASE}/advisersupportstaffdeclaration`,
      additionalHeaders: {
        CMSapikey: MLCL_SITECORE_CMS_KEY,
      },
      data,
      callback: dataReceived => {
        callback({ ...dataReceived })
        return { data: dataReceived }
      },
    })
  }

export const saveActiveSupportStaffData = (data: Object) => ({
  type: SAVE_ACTIVE_SUPPORT_STAFF_DATA,
  payload: {
    data,
  },
})

export const resetAdvisorSupportStaff = () => ({
  type: RESET_ADVISOR_SUPPORT_STAFF,
})

export const saveActiveAdviserData = (data: Object) => ({
  type: SAVE_ACTIVE_ADVISER_DATA,
  payload: {
    data,
  },
})

export const getSupportStaffDetails = (payload: Object, callback: Function) => ({
  type: EXPERIENCE_API,
  name: FETCH_SUPPORT_STAFF_DETAILS,
  verb: 'GET',
  route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/members/${payload.bancsCustomerNo}`,
  callback: (err, dataReceived) => {
    const details = get(dataReceived, 'businessData.member', {})
    callback(err, details)
    return dataReceived
  },
})

export const updateSupportStaffDetails =
  (data: Object, callback: Function) => (dispatch: Function) => {
    dispatch({
      type: EXPERIENCE_API,
      name: UPDATE_SUPPORT_STAFF_DETAILS,
      verb: 'PUT',
      data: {
        member: data,
      },
      additionalHeaders: {
        'X-InitialRole': 'Support_staff',
      },
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/members/${getBancsCustomerNumber()}`,
      callback: (err, dataReceived) => {
        callback(dataReceived, err)
        const details = get(dataReceived, 'businessData.member', {})
        dispatch(saveActiveSupportStaffData(details))
      },
    })
  }

/**
 * Action to request onboard support staff api
 * @param {object} - supportStaffRegisterFormFields
 * @param {string} - path param
 * @returns {function} - action creator
 */
export const onboardSupportStaff =
  (supportStaffRegisterFormFields, path = null) =>
  async (dispatch, getState): Object => {
    const {
      okta: { token },
      masterList: {
        data: { countryCodeList },
      },
    } = getState()

    const xTransactionId = uuidv4()

    const response = await callExternalAPIv2({
      addtionalHeaders: {
        'X-Timestamp': moment().toISOString(),
        'X-TransactionId': xTransactionId,
        Authorization: `Bearer ${token}`,
      },
      version: '',
      removeHeaders: [
        'X-InitialUser',
        'X-InitialRole',
        'bancsCustomerNo',
        'timestamp',
        'creationTime',
        'source',
        'transactionId',
        'trackingId',
        'adviserNo',
      ],
      requestBody: buildSupportStaffOnboardPayload(supportStaffRegisterFormFields, {
        countryCodeList,
      }),
      isUnauthenticatedRequest: true,
      method: 'POST',
      path: path ?? '/retail-connect/v1/adviser/support-staff/onboard',
      toGateway: true,
      xTransactionId,
    })

    if (response) {
      toast(TOAST_TITLE_ONBOARD_SUPPORT_STAFF_SUCCESS, {
        autoClose: 3000,
        toastId: TOAST_ID_ONBOARD_SUPPORT_STAFF,
        type: toast.TYPE.SUCCESS,
      })
    } else {
      toast(TOAST_TITLE_ONBOARD_SUPPORT_STAFF_ERROR, {
        autoClose: 3000,
        toastId: TOAST_ID_ONBOARD_SUPPORT_STAFF,
        type: toast.TYPE.ERROR,
      })
    }

    return response
  }
