import styled from '@emotion/styled'
import { Button, Checkbox, Heading, Icons, Input } from '@mlcl-digital/mlcl-design'
import TableV2 from '@mlcl-digital/mlcl-design/lib/base/Table'
import { RichText } from '@sitecore-jss/sitecore-jss-react'
import React, { useState, useEffect, useRef, RefCallback } from 'react'
import isEqual from 'lodash/isEqual'
import mapValues from 'lodash/mapValues'

// redux.
import { useSelector, useDispatch } from 'react-redux'

// components.
// @ts-expect-error file not in typescript
import Dropzone from '../../../../../molecules/FileUpload/components/Dropzone'
// @ts-expect-error file not in typescript
import FileUploadingProgress from '../../../../../molecules/FileUpload/components/FileUploadingProgress'

// utils
// @ts-expect-error file not in typescript
import { actionCreators } from '../../../../../../actions'
import { createEvent } from '../../../../../../utils/telemetry'
import {
  generateFileKey,
  convertByteFileSizeToKB,
  csvParser,
  checkIsUploadProgress,
  // @ts-expect-error file not in typescript
} from '../../../../../../utils/fileUpload'
import {
  errorCheck,
  // @ts-expect-error file not in typescript
} from '../../../../../../utils/formUtils'
import usePrevious from '../../../../../../hooks/usePrevious'
import useMultiRefs from '../../../../../../hooks/useMultiRefs'

import schema, { FORM_ID } from './supportStaffBulkUpload.schema'
// types
import { fieldsType } from '../../../../../../types/components/SupportStaffRegisterForm'
import { Store } from '../../../../../../types/store'
import { FileState } from '../../../../../../types/Document'
import { FormFields, FormError, FormField } from '../../../../../../types/forms'
import { CountryCodeList } from '../../../../../../types/masterData'

// styles
import styles from './supportStaffBulkUpload.styles'

// constants
import { MIME_TPYE_CSV, CSV_FILE_ROW_LIMIT } from '../../../../../../constants/fileUpload'

type SupportStaffRegisterFormProps = {
  // To render all labels from sitecore content editor
  fields: fieldsType
  originalFields: Record<string, object | object[]>
  handleBack: (setShowRegisterForm: string) => void
  setRetrieveASF: (setRetrieveASF: boolean) => void
  retrieveASF: boolean
}

type instructionValueType = { Instruction: { value: string } }
type instructionType = {
  fields: instructionValueType
}

const SupportStaffBulkUploadWrapper = styled('section')(styles.supportStaffBulkUploadWrapper)
const ValidationSummarySection = styled('section')(styles.validationSummarySection)
const ErrorHeading = styled('p')(styles.errorHeading)
const BulkUploadDescriptionMsg = styled('p')(styles.bulkUploadDescription)
const NavButton = styled(Button)(styles.nav)
const BulkUploadInstructions = styled('ol')(styles.bulkUploadInstructions)
const ErrorsUl = styled('ul')(styles.errorsUl)
const ListItem = styled('li')(styles.listItem)
const ErrorListItem = styled('li')(styles.errorListItem)
const CSVDropzoneWrapper = styled('div')(styles.CSVDropzoneWrapper)
const FilelistWrapper = styled('div')(styles.filelistWrapper)
const { IconChevronLeft16 } = Icons as { [key: string]: React.ReactNode }
const Footer = styled('footer')(styles.footer)
const SupportStaffBulkUploadDeclarationGroupWrapper = styled('section')(
  styles.supportStaffBulkUploadDeclarationGroupWrapper
)
const UploadBtn = styled(Button)(styles.uploadBtn)
const DeclarationCheckbox = styled(Checkbox)(styles.declarationCheckbox)
const DeleteBtn = styled(Button)({
  '& >a': {
    color: 'inherit',
    textDecoration: 'inherit',
  },
})

// @ts-expect-error supress error
const CellInput = styled(Input)(({ error }) => ({
  marginBottom: 0,
  height: '100%',
  width: '100%',
  div: {
    height: '100%',
    input: {
      height: '100%',
      outline: 'none',
      appearance: 'none',
      border: !error && 'none',
      borderWidth: !error && 'none',
      backgroundColor: !error && 'unset',
      padding: '0 10px',
      ':focus': {
        border: 'solid',
      },
    },
  },
}))

export const renderFileList = (
  fields: fieldsType,
  files: FileState[],
  handleFileRemove: (filedId: string) => void,
  validateFiles: (list: {
    [key: number]: File
  }) => Promise<Record<string, string | boolean | File | string[]>[]>
) => {
  const fileUploadMeta = {
    fileUploading: 'Uploading',
    fileUploadSuccess: 'Upload Success!',
    fileUploadFailure: 'Upload failure',
    fileRemoveButtonLabel: 'Remove',
  }

  return (
    <>
      {files.map(fileData => {
        const fileKey = generateFileKey(fileData.file)
        return (
          <FileUploadingProgress
            key={fileKey}
            handleFileRemove={handleFileRemove}
            fileUploadMeta={fileUploadMeta}
            validateFiles={validateFiles}
            fileData={fileData}
            showFullFileName
          />
        )
      })}
    </>
  )
}

const columns = (fields: fieldsType) => [
  {
    Header: fields.SerialNoTableHeader,
    id: 'rowNo',
    accessor: 'rowNo',
    disableSortBy: true,
  },
  {
    Header: fields.FirstNameTableHeader,
    id: 'firstName',
    accessor: 'firstName',
    disableSortBy: true,
  },
  {
    Header: fields.LastNameTableHeader,
    id: 'lastName',
    accessor: 'lastName',
    disableSortBy: true,
  },
  {
    Header: fields.DOBTableHeader,
    id: 'dateOfBirth',
    accessor: 'dateOfBirth',
    disableSortBy: true,
  },
  {
    Header: fields.CountryCodeTableHeader,
    id: 'countryCode',
    accessor: 'countryCode',
    disableSortBy: true,
  },
  {
    Header: fields.MobileTableHeader,
    id: 'mobileNumber',
    accessor: 'mobileNumber',
    disableSortBy: true,
  },
  {
    Header: fields.EmailTableHeader,
    id: 'emailAddress',
    accessor: 'emailAddress',
    disableSortBy: true,
  },
]

const validationColumns = (
  fields: fieldsType,
  formParties: FormFields[],
  handleChange: (
    e: { name: string; value: string; iChangeEvent: React.ChangeEvent<HTMLInputElement> },
    index: number
  ) => void,
  deleteParty: (index: number) => void,
  addMultiRef: RefCallback<HTMLInputElement>
) => [
  {
    Header: fields.SerialNoTableHeader,
    id: 'rowNo',
    accessor: 'rowNo',
    disableSortBy: true,
  },
  {
    Header: fields.FirstNameTableHeader,
    id: 'firstName',
    accessor: 'firstName',
    disableSortBy: true,
    Cell: ({
      row: { index },
    }: {
      row: {
        index: number
      }
    }) => (
      <CellInput
        passedRef={addMultiRef}
        htmlFor={`firstName-${index}`}
        name="firstName"
        changeHandler={(e: {
          name: string
          value: string
          iChangeEvent: React.ChangeEvent<HTMLInputElement>
        }) => handleChange(e, index)}
        value={formParties?.[index]?.firstName?.value}
        error={formParties?.[index]?.firstName?.error?.error}
      />
    ),
  },
  {
    Header: fields.LastNameTableHeader,
    id: 'lastName',
    accessor: 'lastName',
    disableSortBy: true,
    Cell: ({
      row: { index },
    }: {
      row: {
        index: number
      }
    }) => (
      <CellInput
        passedRef={addMultiRef}
        htmlFor={`lastName-${index}`}
        name="lastName"
        changeHandler={(e: {
          name: string
          value: string
          iChangeEvent: React.ChangeEvent<HTMLInputElement>
        }) => handleChange(e, index)}
        value={formParties?.[index]?.lastName?.value}
        error={formParties?.[index]?.lastName?.error?.error}
      />
    ),
  },
  {
    Header: fields.DOBTableHeader,
    id: 'dateOfBirth',
    accessor: 'dateOfBirth',
    disableSortBy: true,
    Cell: ({
      row: { index },
    }: {
      row: {
        index: number
      }
    }) => (
      <CellInput
        passedRef={addMultiRef}
        htmlFor={`dateOfBirth-${index}`}
        name="dateOfBirth"
        changeHandler={(e: {
          name: string
          value: string
          iChangeEvent: React.ChangeEvent<HTMLInputElement>
        }) => handleChange(e, index)}
        value={formParties?.[index]?.dateOfBirth?.value}
        error={formParties?.[index]?.dateOfBirth?.error?.error}
      />
    ),
  },
  {
    Header: fields.CountryCodeTableHeader,
    id: 'countryCode',
    accessor: 'countryCode',
    disableSortBy: true,
    Cell: ({
      row: { index },
    }: {
      row: {
        index: number
      }
    }) => (
      <CellInput
        passedRef={addMultiRef}
        htmlFor={`countryCode-${index}`}
        name="countryCode"
        changeHandler={(e: {
          name: string
          value: string
          iChangeEvent: React.ChangeEvent<HTMLInputElement>
        }) => handleChange(e, index)}
        value={formParties?.[index]?.countryCode?.value}
        error={formParties?.[index]?.countryCode?.error?.error}
      />
    ),
  },
  {
    Header: fields.MobileTableHeader,
    id: 'mobileNumber',
    accessor: 'mobileNumber',
    disableSortBy: true,
    Cell: ({
      row: { index },
    }: {
      row: {
        index: number
      }
    }) => (
      <CellInput
        passedRef={addMultiRef}
        htmlFor={`mobileNumber-${index}`}
        name="mobileNumber"
        changeHandler={(e: {
          name: string
          value: string
          iChangeEvent: React.ChangeEvent<HTMLInputElement>
        }) => handleChange(e, index)}
        value={formParties?.[index]?.mobileNumber?.value}
        error={formParties?.[index]?.mobileNumber?.error?.error}
      />
    ),
  },
  {
    Header: fields.EmailTableHeader,
    id: 'emailAddress',
    accessor: 'emailAddress',
    disableSortBy: true,
    Cell: ({
      row: { index },
    }: {
      row: {
        index: number
      }
    }) => (
      <CellInput
        passedRef={addMultiRef}
        htmlFor={`emailAddress-${index}`}
        name="emailAddress"
        changeHandler={(e: {
          name: string
          value: string
          iChangeEvent: React.ChangeEvent<HTMLInputElement>
        }) => handleChange(e, index)}
        value={formParties?.[index]?.emailAddress?.value}
        error={formParties?.[index]?.emailAddress?.error?.error}
      />
    ),
  },
  {
    Header: '',
    id: 'action',
    accessor: 'action',
    disableSortBy: true,
    Cell: ({
      row: { index },
    }: {
      row: {
        index: number
      }
    }) => (
      <div>
        <DeleteBtn variant="danger" onClick={() => deleteParty(index)}>
          Delete
        </DeleteBtn>
      </div>
    ),
  },
]

const supportStaffBulkUpload = (props: SupportStaffRegisterFormProps) => {
  const { fields, handleBack, originalFields, setRetrieveASF, retrieveASF } = props
  const {
    BulkUploadHeading,
    backButtonLabel,
    AddMultipleUploadButtonLabel,
    BulkUploadDescription,
    FileSizeExceeds,
    MaxRecordsMessage,
    FileCorruptedMessage,
    FileCountMessage,
    UnsupportedFileType,
    BulkUploadSupportStaffList,
    BulkUploadSupportStaffListConfirmation,
    BulkUploadDeclarations,
    BulkUploadDeclarationsContent,
    SubmitButtonLabel,
    ValidationSumary,
    NameValidationSumary,
    NameRequiredMessage,
    NameMaxLengthMessage,
    NameInvalidMessage,
    DOBValidationSumary,
    supportStaffRegisterDateOfBirthRequiredError,
    DOBInvalidFormatMessage,
    supportStaffRegisterDateOfBirthFutureDateError,
    CountryValidationSumary,
    CountryCodeRequiredMessage,
    CountryCodeInvalidFormatMessage,
    MobilenumberValidationSumary,
    supportStaffRegisterContactNumberRequiredError,
    ValidMobileNumberMessage,
    EmailValidationSumary,
    supportStaffRegisterEmailRequiredError,
    supportStaffRegisterEmailInvalidError,
    supportStaffRemoveSuccessModalConfirmButton,
  } = fields
  const {
    browseFilesToUpload,
    removeFile,
    resetFilesData,
    formInit,
    formUpdateField,
    formReset,
    onboardSupportStaff,
  } = actionCreators
  const fileUploadInfoFiles = useSelector((state: Store) => state.fileUploadInfo.files)
  const [isBrowseDropNotAllowed, setBrowseDropNotAllowed] = useState(false)
  const [mainComponent, setMainComponent] = useState('bulkUploadLanding')
  const [parsedCSV, setParsedCSV] = useState([])
  const [tickDeclaratioin, setTickDeclaration] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const form = useSelector((state: Store) => state.forms[FORM_ID])
  const dispatch = useDispatch()
  const formPartiesFIelds = form?.fields?.parties as unknown as {
    error: boolean
    value: FormFields[]
  }
  const formParties = form?.fields?.parties?.value as unknown as FormFields[]
  const prevFormParties = usePrevious<FormFields[]>(formParties)
  const focusTargetRef = useRef<HTMLInputElement | null>(null)
  const countriesPhoneCode = useSelector(
    (state: Store) => state.masterList?.data?.countryCodeList
  ) as CountryCodeList[]
  // addMultiRef is used to add all the cell components,
  // this is for the issue when input change input focus will lose
  // TODO: maybe try to refactor the table component in desgin lib
  const [getRefs, addMultiRef] = useMultiRefs<HTMLInputElement>()
  const errorTypes: Record<string, string[]> = {
    name: [NameRequiredMessage, NameInvalidMessage, NameMaxLengthMessage],
    dob: [
      supportStaffRegisterDateOfBirthRequiredError,
      DOBInvalidFormatMessage,
      supportStaffRegisterDateOfBirthFutureDateError,
    ],
    countryCode: [CountryCodeRequiredMessage, CountryCodeInvalidFormatMessage],
    mobileNumber: [supportStaffRegisterContactNumberRequiredError, ValidMobileNumberMessage],
    email: [supportStaffRegisterEmailRequiredError, supportStaffRegisterEmailInvalidError],
  }
  const validationErrorsCount: Record<string, number> = formParties?.reduce(
    (errors, party) => {
      const updatedErrors = { ...errors }
      Object.keys(party).forEach(field => {
        if (party[field]?.error?.error) {
          Object.keys(errorTypes).forEach((key: string) => {
            // @ts-expect-error suppress error
            if (errorTypes[key].includes(party[field]?.error?.errorMsg)) updatedErrors[key] += 1
          })
        }
      })
      return updatedErrors
    },
    { name: 0, dob: 0, countryCode: 0, mobileNumber: 0, email: 0 }
  )

  const validateBulkUploadForm = () => {
    let isValid = true
    if (formParties?.length === 0) isValid = false

    const updatedPartiesValue = formParties?.map(party => {
      const newRep = mapValues(party, (partyObj, eachField) => {
        const { value } = partyObj
        const newData = {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          error: errorCheck(
            value,
            schema(fields, countriesPhoneCode)?.[eachField]?.condition,
            null,
            ['mobileNumber'].includes(eachField) ? party : {}
          ),
          value,
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (newData?.error?.error) {
          isValid = false
        }
        return newData
      })
      return newRep
    })
    const updatedParties = { error: !isValid, value: updatedPartiesValue }
    // Update the filed value
    dispatch(formUpdateField(FORM_ID, 'parties', updatedParties))
    return isValid
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(resetFilesData())
  }, [])

  useEffect(() => {
    dispatch(
      formInit(FORM_ID, { parties: [] }, null, {
        parties: {
          error: false,
          value: parsedCSV?.map(csvRow =>
            Object.keys(csvRow).reduce((transformedData: Record<string, object>, key) => {
              // add 0 at the start of dob to meet dd format
              let isAddingZero = false
              if (key === 'dateOfBirth') {
                // @ts-expect-error suppress error
                // eslint-disable-next-line max-len
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                const firstDateSection: string = csvRow[key]?.split('/')[0]
                if (
                  firstDateSection &&
                  firstDateSection?.length === 1 &&
                  !firstDateSection?.startsWith('0')
                ) {
                  isAddingZero = true
                }
              }
              transformedData[key] = {
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                value: key === 'dateOfBirth' && isAddingZero ? `0${csvRow[key]}` : csvRow[key],
                error: { error: false },
              }
              return transformedData
            }, {})
          ),
        },
      })
    )
  }, [parsedCSV])

  useEffect(() => {
    if (
      prevFormParties?.length === 0 ||
      // this is a quick fix for bulk upload deletion not form validation issue GRW-3394
      (prevFormParties?.length ?? 0) > (formParties?.length ?? 0)
    )
      validateBulkUploadForm()
    const allRefs = getRefs()
    focusTargetRef?.current && allRefs.find(r => r?.id === focusTargetRef?.current?.id)?.focus()
  }, [JSON.stringify(formParties)])

  const validateFiles = async (list: { [key: number]: File }) => {
    const data = []
    // eslint-disable-next-line no-restricted-syntax
    for (const file of Object.values(list)) {
      let result: Record<string, boolean | string | File | string[]> = {
        hasValidationError: false,
        fileValidationErrorLabel: [],
      }
      let fileSizeInKB = 0
      const fileId = generateFileKey(file)
      fileSizeInKB = convertByteFileSizeToKB(file.size)
      result = { ...result, file, fileId }
      // eslint-disable-next-line no-await-in-loop
      const { parsedCSVResult, columnHeaders } = await csvParser(file, { type: MIME_TPYE_CSV })
      if (fileSizeInKB > 20480) {
        result = {
          ...result,
          fileValidationErrorLabel: [
            ...(result.fileValidationErrorLabel as string[]),
            FileSizeExceeds,
          ],
          hasValidationError: true,
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setParsedCSV(parsedCSVResult)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (parsedCSVResult?.length > CSV_FILE_ROW_LIMIT) {
        result = {
          ...result,
          fileValidationErrorLabel: [
            ...(result.fileValidationErrorLabel as string[]),
            MaxRecordsMessage,
          ],
          hasValidationError: true,
        }
      }
      if (Object.keys(list).length > 1 || fileUploadInfoFiles.length > 0) {
        setBrowseDropNotAllowed(true)
        result = {
          ...result,
          fileValidationErrorLabel: [
            ...(result.fileValidationErrorLabel as string[]),
            FileCountMessage,
          ],
          hasValidationError: true,
        }
      }

      if (file.type !== MIME_TPYE_CSV) {
        // eslint-disable-next-line no-undef
        result = {
          ...result,
          hasValidationError: true,
          fileValidationErrorLabel: [
            ...(result.fileValidationErrorLabel as string[]),
            UnsupportedFileType,
          ],
        }
      }

      if (
        !isEqual(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          new Set(columnHeaders),
          new Set([
            'firstName',
            'lastName',
            'dateOfBirth',
            'countryCode',
            'mobileNumber',
            'emailAddress',
          ])
        )
      ) {
        result = {
          ...result,
          hasValidationError: true,
          fileValidationErrorLabel: [
            ...(result.fileValidationErrorLabel as string[]),
            FileCorruptedMessage,
          ],
        }
      }

      data.push(result)
    }
    return data
  }

  const handleFileRemove = (filedId: string) => {
    if (filedId !== '') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      dispatch(removeFile(fileUploadInfoFiles, filedId))
      dispatch(formReset(FORM_ID, { parties: [] }, { parties: [] }))
    }
  }

  const handleFileDrop = (file: Record<string, string | boolean | File | string[]>[]) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(browseFilesToUpload(file))
  }

  // prevent default behavior of opening the file directly
  const onDragOver = (evt: React.DragEvent<HTMLDivElement>) => evt.preventDefault()

  const onDrop = async (evt: React.DragEvent<HTMLDivElement>) => {
    // prevent default behavior of opening the file directly
    evt.preventDefault()

    const { files } = evt.dataTransfer
    const isFileUploading = checkIsUploadProgress(fileUploadInfoFiles) as Array<File>[]
    if (isFileUploading.length) return

    // eslint-disable-next-line no-unused-expressions
    Object.keys(files).length && handleFileDrop(await validateFiles(files))
  }

  const handleUploadClick = () =>
    Object.keys(validationErrorsCount ?? {}).some(key => validationErrorsCount?.[key] > 0)
      ? setMainComponent('bulkUploadValidation')
      : setMainComponent('bulkUploadReview')

  const renderInstructionList = (bulkUploadInstructionList: instructionType[]) =>
    bulkUploadInstructionList.map(listItem => (
      <ListItem>
        <RichText field={{ value: Object.values(listItem.fields)[0].value }} />
      </ListItem>
    ))

  const isUploadBtnDisabled =
    fileUploadInfoFiles?.length < 1 || fileUploadInfoFiles?.some(f => f.hasValidationError === true)

  const handleChange = (
    e: { name: string; value: string; iChangeEvent: React.ChangeEvent<HTMLInputElement> },
    index: number
  ) => {
    const { name, value, iChangeEvent } = e
    const data = {
      error: errorCheck(
        value,
        schema(fields, countriesPhoneCode)?.[name]?.condition,
        null,
        ['mobileNumber'].includes(name) ? formParties[index] : {}
      ) as FormError,
      value,
    }

    const updatedParties = [...formParties]
    updatedParties[index][name] = data as FormField
    focusTargetRef.current = iChangeEvent.target
    // Update the parent form data
    dispatch(formUpdateField(FORM_ID, 'parties', { value: updatedParties }))
    validateBulkUploadForm()
  }

  const deleteParty = (index: number) => {
    const updatedParties = [...formParties]
    updatedParties.splice(index, 1)
    dispatch(formUpdateField(FORM_ID, 'parties', { value: updatedParties }))
  }

  const csvData = formParties?.map((csvRowData: object, index) => ({
    ...mapValues(csvRowData, (rowDataObj: { value: string }) => rowDataObj?.value),
    rowNo: `${index + 1}.`,
  }))

  const handleBulkUploadSubmit = async () => {
    const isFieldsValid = !form?.fields?.parties?.error
    if (isFieldsValid) {
      setSubmitLoading(true)
      // GA tags and Splunk custom event
      const event = createEvent({
        GA: {
          category: 'Support staff',
          action: 'AP - Add multiple staff submit',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'Add multiple staff submit',
          },
        },
      })
      event.end()
      const response = (await dispatch(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        onboardSupportStaff(
          form.fields.parties.value,
          '/retail-connect/v1/adviser/support-staff/bulk-onboard'
        )
      )) as object
      setSubmitLoading(false)
      if (response) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        setRetrieveASF(!retrieveASF)
        handleBack('SupportStaffListSection')
      }
    }
  }

  const componentMap: Record<string, JSX.Element> = {
    bulkUploadLanding: (
      <>
        <BulkUploadDescriptionMsg>
          <RichText field={{ value: BulkUploadDescription }} />
        </BulkUploadDescriptionMsg>
        <BulkUploadInstructions>
          {renderInstructionList(originalFields.BulkUploadInstructionList as instructionType[])}
        </BulkUploadInstructions>
        <CSVDropzoneWrapper
          onDragOver={onDragOver}
          onDrop={e => {
            // async iife needed as ondrop expect an void return not a promise,
            // refer to https://typescript-eslint.io/rules/no-misused-promises/
            void (async () => {
              await onDrop(e)
            })()
          }}
        >
          <Dropzone
            hasMultipleFiles={false}
            handleFileDrop={handleFileDrop}
            isFileUploading={fileUploadInfoFiles?.length}
            validateFiles={validateFiles}
            bulkUploadIcon
            acceptFileType=".csv"
            isBrowseDropNotAllowed={isBrowseDropNotAllowed}
          />
          <FilelistWrapper>
            {fileUploadInfoFiles && fileUploadInfoFiles.length
              ? renderFileList(fields, fileUploadInfoFiles, handleFileRemove, validateFiles)
              : null}
          </FilelistWrapper>
        </CSVDropzoneWrapper>
        <Footer>
          <UploadBtn type="secondary" disabled={isUploadBtnDisabled} onClick={handleUploadClick}>
            {AddMultipleUploadButtonLabel}
          </UploadBtn>
        </Footer>
      </>
    ),
    bulkUploadReview: (
      <>
        <Heading variant="h3" size="large" styleOverrides={{ marginTop: '38px', marginBottom: 0 }}>
          {BulkUploadSupportStaffList}
        </Heading>
        <BulkUploadDescriptionMsg>
          {BulkUploadSupportStaffListConfirmation}
        </BulkUploadDescriptionMsg>
        <TableV2
          columns={columns(fields)}
          data={csvData}
          pageSize={10}
          isLoading={false}
          styleOverrides={{
            headerCell: {
              minWidth: 'auto',
              '&: first-of-type': {
                flexGrow: 0.3,
              },
              '&: last-of-type': {
                flexGrow: 2.5,
              },
            },
            cell: {
              minWidth: 'auto',
              '&: first-of-type': {
                flexGrow: 0.3,
              },
              '&: last-of-type': {
                flexGrow: 2.5,
              },
            },
            footer: {
              justifyContent: 'center',
              borderTop: '1px solid #BDBDBD',
              marginTop: '72px',
            },
          }}
        />
        <SupportStaffBulkUploadDeclarationGroupWrapper>
          <Heading variant="h3" size="medium">
            {BulkUploadDeclarations}
          </Heading>
          <DeclarationCheckbox
            text={<RichText field={{ value: BulkUploadDeclarationsContent }} />}
            name="isSupportStaffBulkUploadCheckboxSelected"
            htmlFor="isSupportStaffBulkUploadCheckboxSelected"
            onChangeHandler={() => setTickDeclaration(!tickDeclaratioin)}
            checked={tickDeclaratioin}
          />
        </SupportStaffBulkUploadDeclarationGroupWrapper>
        <Footer style={{ paddingTop: '90px' }}>
          <UploadBtn
            type="secondary"
            isLoading={submitLoading}
            disabled={!tickDeclaratioin}
            onClick={handleBulkUploadSubmit}
          >
            {SubmitButtonLabel}
          </UploadBtn>
        </Footer>
      </>
    ),
    bulkUploadValidation: (
      <>
        <Heading variant="h3" size="large" styleOverrides={{ marginTop: '38px', marginBottom: 0 }}>
          {BulkUploadSupportStaffList}
        </Heading>
        {Object.keys(validationErrorsCount ?? {}).some(key => validationErrorsCount?.[key] > 0) && (
          <>
            <BulkUploadDescriptionMsg>{ValidationSumary}</BulkUploadDescriptionMsg>
            <ValidationSummarySection>
              <ErrorHeading>
                {NameValidationSumary.replace('$', `${validationErrorsCount?.name}`)}
              </ErrorHeading>
              <ErrorsUl>
                <ErrorListItem>{NameRequiredMessage}</ErrorListItem>
                <ErrorListItem>{NameInvalidMessage}</ErrorListItem>
                <ErrorListItem>{NameMaxLengthMessage}</ErrorListItem>
              </ErrorsUl>
              <ErrorHeading>
                {DOBValidationSumary.replace('$', `${validationErrorsCount?.dob}`)}
              </ErrorHeading>
              <ErrorsUl>
                <ErrorListItem>{supportStaffRegisterDateOfBirthRequiredError}</ErrorListItem>
                <ErrorListItem>{DOBInvalidFormatMessage}</ErrorListItem>
                <ErrorListItem>{supportStaffRegisterDateOfBirthFutureDateError}</ErrorListItem>
              </ErrorsUl>
              <ErrorHeading>
                {CountryValidationSumary.replace('$', `${validationErrorsCount?.countryCode}`)}
              </ErrorHeading>
              <ErrorsUl>
                <ErrorListItem>{CountryCodeRequiredMessage}</ErrorListItem>
                <ErrorListItem>{CountryCodeInvalidFormatMessage}</ErrorListItem>
              </ErrorsUl>
              <ErrorHeading>
                {MobilenumberValidationSumary.replace(
                  '$',
                  `${validationErrorsCount?.mobileNumber}`
                )}
              </ErrorHeading>
              <ErrorsUl>
                <ErrorListItem>{supportStaffRegisterContactNumberRequiredError}</ErrorListItem>
                <ErrorListItem>{ValidMobileNumberMessage}</ErrorListItem>
              </ErrorsUl>
              <ErrorHeading>
                {EmailValidationSumary.replace('$', `${validationErrorsCount?.email}`)}
              </ErrorHeading>
              <ErrorsUl>
                <ErrorListItem>{supportStaffRegisterEmailRequiredError}</ErrorListItem>
                <ErrorListItem>{supportStaffRegisterEmailInvalidError}</ErrorListItem>
              </ErrorsUl>
            </ValidationSummarySection>
          </>
        )}
        <TableV2
          columns={validationColumns(fields, formParties, handleChange, deleteParty, addMultiRef)}
          data={csvData}
          pageSize={10}
          currentIndex={Math.floor(+(focusTargetRef?.current?.id?.replace(/\D/gi, '') ?? 0) / 10)}
          isLoading={false}
          styleOverrides={{
            base: { marginTop: '48px' },
            headerCell: {
              padding: 0,
              minWidth: 'auto',
              textAlign: 'left',
              paddingLeft: '18px',
              '&: first-of-type': {
                flexGrow: 0.5,
                justifyContent: 'center',
              },
              '&: nth-of-type(2)': {
                flexGrow: 0.8,
              },
              '&: nth-of-type(3)': {
                flexGrow: 0.8,
              },
              '&: nth-of-type(4)': {
                flexGrow: 0.9,
              },
              '&: nth-of-type(5)': {
                flexGrow: 0.7,
              },
              '&: nth-last-of-type(2)': {
                flexGrow: 2.4,
              },
              '&: last-of-type': {
                flexGrow: 1.3,
              },
            },
            cell: {
              padding: 0,
              minWidth: 'auto',
              justifyContent: 'center',
              '&: first-of-type': {
                flexGrow: 0.5,
              },
              '&: nth-of-type(2)': {
                flexGrow: 0.9,
              },
              '&: nth-of-type(3)': {
                flexGrow: 0.9,
              },
              '&: nth-of-type(5)': {
                flexGrow: 0.8,
              },
              '&: nth-last-of-type(2)': {
                flexGrow: 3,
              },
              '&: nth-of-type(6)': {
                flexGrow: 1.2,
              },
              '&: last-of-type': {
                flexGrow: 1.3,
              },
            },
            footer: {
              justifyContent: 'center',
              borderTop: '1px solid #BDBDBD',
              marginTop: '72px',
            },
          }}
        />
        <Footer style={{ paddingTop: '90px' }}>
          <UploadBtn
            type="secondary"
            disabled={formPartiesFIelds?.error}
            onClick={() => {
              setMainComponent('bulkUploadReview')
            }}
          >
            {supportStaffRemoveSuccessModalConfirmButton}
          </UploadBtn>
        </Footer>
      </>
    ),
  }

  return (
    <SupportStaffBulkUploadWrapper>
      <NavButton
        data-testid="backButton"
        onClick={() =>
          mainComponent === 'bulkUploadLanding'
            ? handleBack('SupportStaffListSection')
            : setMainComponent('bulkUploadLanding')
        }
        variant="tertiary"
      >
        {/* @ts-expect-error non-TS-code  */}
        <IconChevronLeft16 />
        {backButtonLabel}
      </NavButton>
      <Heading variant="h2" size="LGR">
        {BulkUploadHeading}
      </Heading>
      {componentMap[mainComponent]}
    </SupportStaffBulkUploadWrapper>
  )
}

export default supportStaffBulkUpload
