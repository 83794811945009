import { RefCallback, useRef } from 'react'

/**
 * Callback to filter stored Refs to only those available on the DOM
 */
export type GetRefs<T extends Node = Node> = () => T[]

/**
 * The `useMultiRefs` React hook collects a list of DOM nodes using `RefCallback`
 * function and filters the returned list by only those nodes still present
 * in the DOM
 *
 * @returns the function to get the list of nodes, and the `RefCallback` to pass to DOM nodes
 *
 * @example
 * The `addRef` function can be used in as many DOM nodes as necessary, and all those currently
 * rendered in the DOM will be counted by the callback `getRefs` function.
 *
 * ```javascript
 * return default function MyComponent() {
 *   const [getRefs, addRef] = useMultiRefs()
 *   return (
 *     <>
 *       <div ref={addRef}>First Component</div>
 *       <div ref={addRef}>Second Component</div>
 *       <div>Storing { getRefs().length } components</div>
 *     </>
 *   )
 * }
 * ```
 */
const useMultiRefs = <T extends Node = Node>(): [GetRefs<T>, RefCallback<T>] => {
  const refs = useRef(new Set<T>())

  const getRefs: GetRefs<T> = () => Array.from(refs.current).filter(ref => document.contains(ref))

  const addRef: RefCallback<T> = (ref: T | null) => {
    if (ref !== null) {
      refs.current.add(ref)
    }
  }

  return [getRefs, addRef]
}

export default useMultiRefs
