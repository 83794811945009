/* eslint-disable no-param-reassign */
import { required, phone } from '../../../../../utils/formUtils'

export const FORM_ID = 'underWritingForm'
export const PRIMARY = 'primary'
export const OTHER = 'other'
export const DAYS = [
  { label: 'Monday', value: 'Monday' },
  { label: 'Tuesday', value: 'Tuesday' },
  { label: 'Wednesday', value: 'Wednesday' },
  { label: 'Thursday', value: 'Thursday' },
  { label: 'Friday', value: 'Friday' },
]

function getAlternateContact(preferred, alternateContact) {
  if (!alternateContact) {
    if (preferred.length) {
      return 'primary'
    }
    return ''
  }
  return 'other'
}

export const DEFAULTS = (preferred, alternateContact) => ({
  preferred,
  alternateContactOptions: getAlternateContact(preferred, alternateContact),
  alternateContact,
})

const Schema = (fields, isPrimarySelected) => ({
  alternateContactOptions: {
    condition: '',
    errorMsg: fields.personalStatementFastTrackContactNumberMandatoryError,
  },
  alternateContact: {
    condition: [
      ...(isPrimarySelected === OTHER
        ? [
            {
              condition: required,
              errorMsg: fields.personalStatementFastTrackContactNumberMandatoryError,
            },
            {
              condition: phone,
              errorMsg: fields.personalStatementFastTrackContactNumberValidationError,
            },
          ]
        : [{}]),
    ],
    name: 'alternateContact',
  },
})

export default Schema
